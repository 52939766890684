<template>
    <div class="vfx-detail" :class="theme">
      <button @click="closeVFXDetail" 
      class="close-button" 
      :class="{ 'light-theme': isLightTheme, 'dark-theme': !isLightTheme }">×</button>
  
      <div class="vfx-header">
        <h2>{{ project.projet }}</h2>
        <h3>{{ project.lenght }} - {{ project.date }}</h3>
  
        <div class="vfx-content">
          <h4>
            {{ project.format.includes('music') ? 'Réalisé par' : 'Un film de' }} 
            <span>{{ project.realisat }}</span>
          </h4>
  
          <template v-if="project.prod && project.prod2">
            <h4 v-if="project.siteprod && project.siteprod2">
              Production
              <a :href="project.siteprod" target="_blank">{{ project.prod }}</a>
              &
              <a :href="project.siteprod2" target="_blank">{{ project.prod2 }}</a>
            </h4>
            <h4 v-else-if="project.siteprod">
              Production
              <a :href="project.siteprod" target="_blank">{{ project.prod }}</a>
              &
              <span>{{ project.prod2 }}</span>
            </h4>
            <h4 v-else>
              Production
              <span>{{ project.prod }}</span>
              &
              <span>{{ project.prod2 }}</span>
            </h4>
          </template>
          <template v-else-if="project.prod">
            <h4>
              Production
              <a v-if="project.siteprod" :href="project.siteprod" target="_blank">{{ project.prod }}</a>
              <span v-else>{{ project.prod }}</span>
            </h4>
          </template>
  
          <p class="synopsis">{{ project.description }}</p>
        </div>
      </div>
  
      <!-- Video integration -->
      <div v-show="project.teaser" class="video-container">
        <iframe
          v-show="isYouTubeVideo(project.teaser)"
          :src="getYouTubeEmbedUrl(project.teaser)"
          frameborder="0"
          allowfullscreen
          loading="lazy"
        ></iframe>
        <iframe
          v-show="isVimeoVideo(project.teaser)"
          :src="getVimeoEmbedUrl(project.teaser)"
          frameborder="0"
          allowfullscreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </template>
  
  <script>
  import "./VFXDetail.css";
  
  export default {
    props: {
      project: {
        type: Object,
        required: true,
      },
      closeVFXDetail: {
        type: Function,
        required: true,
      },

      isYouTubeVideo: {
        type: Function,
        required: true,
      },
      isVimeoVideo: {
        type: Function,
        required: true,
      },
      getYouTubeEmbedUrl: {
        type: Function,
        required: true,
      },
      getVimeoEmbedUrl: {
        type: Function,
        required: true,
      },
      theme: { // Ajout de la prop theme
        type: String,
        required: true,
      }
    },
    computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
  },
  };
  </script>
  
  <style scoped>
  
  </style>
  