<template>
  <div class="project-detail" :class="theme">
    <button @click="closeProjectDetail" 
    class="close-button" 
    :class="{ 'light-theme': isLightTheme, 'dark-theme': !isLightTheme }">×</button>

    <div class="project-header">
      <h2 v-html="formatTitle(project.projet)"></h2>
      <h3>{{ project.lenght }} - {{ project.date }}</h3>

      <div class="project-content">
        <h4>
          {{ project.format.includes('music') ? 'Réalisé par' : 'Un film de' }} 
          <span>{{ project.realisat }}</span>
        </h4>

        <template v-if="project.siteDOP">
          <h4>
            Image
            <a :href="project.siteDOP" target="_blank">{{ project.DOP }}</a>
          </h4>
        </template>
        <template v-else-if="project.DOP">
          <h4>Image <span>{{ project.DOP }}</span></h4>
        </template>

        <template v-if="project.prod && project.prod2">
          <h4 v-if="project.siteprod && project.siteprod2">
            Production
            <a :href="project.siteprod" target="_blank">{{ project.prod }}</a>
            &
            <a :href="project.siteprod2" target="_blank">{{ project.prod2 }}</a>
          </h4>
          <h4 v-else-if="project.siteprod">
            Production
            <a :href="project.siteprod" target="_blank">{{ project.prod }}</a>
            &
            <span>{{ project.prod2 }}</span>
          </h4>
          <h4 v-else>
            Production
            <span>{{ project.prod }}</span>
            &
            <span>{{ project.prod2 }}</span>
          </h4>
        </template>

        <template v-else-if="project.prod">
          <h4>
            Production
            <a v-if="project.siteprod" :href="project.siteprod" target="_blank">{{ project.prod }}</a>
            <span v-else>{{ project.prod }}</span>
          </h4>
        </template>

        <p class="synopsis">{{ project.description }}</p>
      </div>
    </div>

    <!-- Video integration -->
    <div v-if="project.video" class="video-container">
      <iframe
        v-if="isYouTubeVideo(project.video)"
        :src="getYouTubeEmbedUrl(project.video)"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <iframe
        v-else-if="isVimeoVideo(project.video)"
        :src="getVimeoEmbedUrl(project.video)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>

    <!-- Image gallery -->
    <div class="project-gallery">
      <div class="gallery-container">
        <div
          class="gallery-pic"
          v-for="index in numberOfImages"
          :key="index"
          @click="openLightbox(getCarouselImage(project['name-id'], index))"
        >
          <img
            :src="getCarouselImage(project['name-id'], index)"
            alt="Image du projet"
          />
        </div>
      </div>
    </div>

    <!-- Lightbox for large images -->
    <div v-if="lightboxImage" class="lightbox" @click="closeLightbox">
      <img :src="lightboxImage" class="lightbox-image" />
    </div>
  </div>
</template>

<script>
import "./ProjectDetail.css";

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    numberOfImages: {
      type: Number,
      required: true,
    },
    closeProjectDetail: {
      type: Function,
      required: true,
    },
    openLightbox: {
      type: Function,
      required: true,
    },
    closeLightbox: {
      type: Function,
      required: true,
    },
    getCarouselImage: {
      type: Function,
      required: true,
    },
    isYouTubeVideo: {
      type: Function,
      required: true,
    },
    isVimeoVideo: {
      type: Function,
      required: true,
    },
    getYouTubeEmbedUrl: {
      type: Function,
      required: true,
    },
    getVimeoEmbedUrl: {
      type: Function,
      required: true,
    },
    lightboxImage: {
      type: String,
      required: false,
      default: null,
    },
    theme: { // Ajout de la prop theme
      type: String,
      required: true,
    }
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
  },
  methods: {
    formatTitle(title) {
      return title.replace(/\|/g, '<br>');
    },
  },
};
</script>

<style scoped>

</style>
