<template>
  <div class="back-to-top" v-if="isVisible" @click="scrollToTop">
    &#8593;
    <!-- Flèche vers le haut -->
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  setup() {
    const isVisible = ref(false);

    const handleScroll = () => {
      // Affiche le bouton si l'utilisateur a fait défiler la page vers le bas de plus de 200 pixels
      isVisible.value = window.scrollY > 200;
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Défilement en douceur vers le haut
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return { isVisible, scrollToTop };
  },
};
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000; /* Couleur de fond */
  color: #fff; /* Couleur de texte */
  border-radius: 50%;
  width: 40px; /* Largeur */
  height: 40px; /* Hauteur */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px; /* Taille de la flèche */
  opacity: 0.7; /* Opacité */
  transition: opacity 0.3s;
  z-index: 5000;
}

.back-to-top:hover {
  opacity: 1; /* Opacité au survol */
}
</style>
