<template>
  <section id="contacts">

    <div class="contact-info"
    :class="theme">
      <h2><a href="mailto:contact@novostudio.fr">contact@novostudio.fr</a></h2>
      <div>
        <p><i class="fa fa-phone"></i><a href="tel:+33617688063"> +33 6 17 68 80 63</a></p>

        <p>
          <a href="https://www.instagram.com/novo_julian/" target="_blank">@novo_julian</a>
      </p>
      <p><i class="fa fa-map-marker"></i> Paris, France</p>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
    computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
  },
}
</script>

<style scoped>
#contacts {
  display: flex;
  justify-content: center;
  margin: 3vh 0;
  padding: 0;
  color: inherit; 
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info h2 {
  font-size: clamp(1.8rem, 4vw, 5rem); /* La taille varie entre 2rem et 5rem en fonction de la largeur de la fenêtre */
  margin-bottom: 20px;
}

.contact-info div {
  display: flex;
  gap: 60px;
}

.contact-info p {
  margin: 10px 0;
  font-size: 1.5em;
}

.contact-info a {
  text-decoration: none;
}

.contact-info.dark a {
  color: white;
}

.contact-info.light a {
  color: black;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Media query : écran plus petit que 768px */
@media (max-width: 768px) {
  .contact-info div {
    flex-direction: column; /* Passe les éléments en colonne sur petits écrans */
    align-items: center;
    gap: 0px; /* Réduit l'espace entre les éléments en colonne */
  }
}

</style>
