<template>
  <div class="project-grid" :class="theme">
    <!-- Filter buttons -->
    <div class="filter-buttons" 
    :class="{ 'light-theme': isLightTheme, 'dark-theme': !isLightTheme }"
    v-if="!selectedProject">
      <button
        v-for="option in formatOptions"
        :key="option.value"
        @click="filterProjects(option.value)"
        :class="{ active: activeFilter === option.value }"
      >
        {{ option.text }}
      </button>
    </div>

    <!-- Project grid -->
    <div class="grid-container" v-if="!selectedProject">
      <div
        class="project-card"
        v-for="project in filteredProjects"
        :key="project.id"
        @click="selectProject(project)"
      >
        <div class="image-container">
          <img
            :src="getProjectImage(project['name-id'])"
            alt="Image du projet"
            class="project-image"
          />
          <div class="overlay">
            <h3 v-html="formatTitle(project.projet)"></h3>
            <h4>{{ project.realisat }}</h4>

          </div>
        </div>
      </div>
    </div>

    <!-- Project details -->
    <ProjectDetail
      v-if="selectedProject"
      :project="selectedProject"
      :numberOfImages="numberOfImages"
      :closeProjectDetail="closeProjectDetail"
      :openLightbox="openLightbox"
      :closeLightbox="closeLightbox"
      :getCarouselImage="getCarouselImage"
      :isYouTubeVideo="isYouTubeVideo"
      :isVimeoVideo="isVimeoVideo"
      :getYouTubeEmbedUrl="getYouTubeEmbedUrl"
      :getVimeoEmbedUrl="getVimeoEmbedUrl"
      :lightboxImage="lightboxImage"
      :theme="theme"
    />



  </div>
  
</template>

<script>
import { ref, onMounted } from "vue";
import ProjectDetail from './ProjectDetail.vue'; 
import "./ProjectGrid.css";

export default {
  components: {
    ProjectDetail,
  },
  props: {
    filteredProjects: {
      type: Array,
      required: true,
    },
    formatOptions: {
      type: Array,
      required: true,
    },
    activeFilter: {
      type: String,
      default: null, // Vous pouvez définir une valeur par défaut si nécessaire
    },
    theme: {
      type: String,
      required: true, // Assurez-vous que le thème est requis
    },
  },
  setup() {
    const projects = ref([]);
    const filteredProjects = ref([]);
    const selectedProject = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const activeFilter = ref(null);
    const numberOfImages = ref([]);
    const lightboxImage = ref(null);

    const formatTitle = (title) => {
    return title.replace(/\|/g, '<br>');
  };

    const formatOptions = ref([
      { value: null, text: "Tous" },
      { value: "fiction", text: "Fiction" },
      { value: "doc", text: "Documentaire" },
      { value: "music", text: "Musique" },
    ]);

    const fetchProjects = async () => {
      try {
        const response = await fetch("https://julian-nouveau.com/api.php");
        if (!response.ok) throw new Error("Erreur lors de la récupération des projets");
        const data = await response.json();

        // Sort projects by sortOrder in descending order
        projects.value = data.sort((a, b) => b.sortOrder - a.sortOrder);
        filteredProjects.value = projects.value;
      } catch (err) {
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    const filterProjects = (format) => {
      activeFilter.value = format;
      filteredProjects.value = format
        ? projects.value
            .filter((project) => project.format.split(",").includes(format))
            .sort((a, b) => b.sortOrder - a.sortOrder) // Sort filtered projects by sortOrder
        : projects.value;
    };


    const getFormatTexts = (formats) => {
      const excludedFormats = ["short", "feature"];
      return formats.split(",")
        .map((format) => format.trim())
        .filter((format) => !excludedFormats.includes(format))
        .map((format) => formatOptions.value.find((option) => option.value === format)?.text || format)
        .join(", ");
    };

    const getProjectImage = (nameId) => {
      return `/images/vignettes/${nameId}_VIGN.webp`;
    };

    const selectProject = (project) => {
      selectedProject.value = project;
      numberOfImages.value = Array.from({ length: project.stills }, (_, i) => i + 1);

      // Scroll vers le haut de la page
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Ajoute un défilement fluide
      });
    };

    const getCarouselImage = (nameId, index) => {
      return `/images/CAROUSEL/${nameId}/${nameId}_${index}.webp`;
    };

    const openLightbox = (imageUrl) => {
      lightboxImage.value = imageUrl;
    };

    const closeLightbox = () => {
      lightboxImage.value = null;
    };

    const closeProjectDetail = () => {
      selectedProject.value = null;
    };

    const isYouTubeVideo = (url) => {
      return url.includes("youtube.com") || url.includes("youtu.be");
    };

    const isVimeoVideo = (url) => {
      return url.includes("vimeo.com");
    };

    const getYouTubeEmbedUrl = (url) => {
      const videoId = url.split("v=")[1]?.split("&")[0] || url.split("/").pop();
      return `https://www.youtube.com/embed/${videoId}`;
    };

    const getVimeoEmbedUrl = (url) => {
      const videoId = url.split("/").pop();
      return `https://player.vimeo.com/video/${videoId}`;
    };

    onMounted(fetchProjects);

    return {
      projects,
      filteredProjects,
      selectedProject,
      loading,
      error,
      formatOptions,
      filterProjects,
      selectProject,
      closeProjectDetail,
      getProjectImage,
      getCarouselImage,
      numberOfImages,
      activeFilter,
      lightboxImage,
      openLightbox,
      closeLightbox,
      isYouTubeVideo,
      isVimeoVideo,
      getYouTubeEmbedUrl,
      getVimeoEmbedUrl,
      getFormatTexts,
      formatTitle,
    };
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
  },
};
</script>

<style>
</style>
