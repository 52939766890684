import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Importer le routeur
import '@fortawesome/fontawesome-free/css/all.css';


const app = createApp(App);

app.use(router); // Utiliser le routeur

app.mount("#app");
