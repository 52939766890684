<template>
  <div :class="theme">
    <header>
      <div id="TitreSite">
        <h1>Julian Nouveau</h1>
        <h2>Colorist - VFX artist</h2>
      </div>


      
      <div id="IconHeader">

        <button @click="toggleTheme" class="theme-toggle">
          <i :class="theme === 'light' ? 'fa-solid fa-sun' : 'fa-solid fa-moon'"></i>
        </button>

        <a href="https://www.instagram.com/novo_julian/" target="_blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
          
        <a href="mailto:contact@novostudio.fr">
          <i class="fa-solid fa-envelope">‌</i>
        </a>

      </div>

    </header>

    <div id="app">
      <router-view :theme="theme" /> <!-- Passe le thème aux composants -->
      <BackToTop :theme="theme" /> <!-- Passe le thème à BackToTop -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import BackToTop from "./components/BackToTop.vue";
import './components/GlobalStyle.css';

export default {
  components: {
    BackToTop,
  },
  setup() {
    const theme = ref('light'); // Thème par défaut

    onMounted(() => {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) {
        theme.value = savedTheme;
        document.body.className = theme.value;
      }
    });

    const toggleTheme = () => {
      theme.value = theme.value === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', theme.value);
      document.body.className = theme.value;
    };

    return { theme, toggleTheme };
  },
};
</script>

<style>
body {
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s, color 0.3s; /* Transition douce pour le changement de thème */
}

/* Theme toggle button styles */
.theme-toggle {
  border: none;
  background: transparent; /* No background */
  cursor: pointer; /* Pointer on hover */
  font-size: 30px; /* Size of the icon */
  color: inherit;
}

header {
  padding: 0 5%;
  margin: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}



#IconHeader {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 2em;
  margin: 5px 20px;
}

#IconHeader a {
  color: inherit;
  margin: 10px;
}

#IconHeader button {
  margin: 5px;
}

#TitreSite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 30px 0;
}

#TitreSite h1 {
  margin: 0 0 0 0;
  padding-top: 5px;
}

#TitreSite h2 {
  margin: 0 0 0 0;
  font-weight: normal;
  font-style: italic;
  padding-top: 5px;
  font-size: small;
}

/* Thème clair */
body.light {
  background-color: white; /* Couleur de fond pour le thème clair */
  color: black; /* Couleur du texte pour le thème clair */
}

/* Thème sombre */
body.dark {
  background-color: #121212; /* Couleur de fond sombre */
  color: white; /* Couleur du texte clair */
}


/* Media query : écran plus petit que 768px */
@media (max-width: 768px) {

  header {
  justify-content: center;
  flex-direction: column;
}

  #TitreSite {
  align-items: center;
  margin: 0;
}

#IconHeader {
  position:relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 2em;
}

}
</style>
