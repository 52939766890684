<template>
  <div class="vfx-grid" :class="theme">
    <!-- Grille des projets VFX, masquée si un projet est sélectionné -->
    <div class="vfx-grid-container" v-if="!Project">
      <div
        class="vfx-card"
        v-for="vfx in vfxProjects"
        :key="vfx.id"
        @click="selectVFX(vfx)"
      >
        <!-- Image du projet ou carton avec le titre -->
        <div class="affiche-container">
          <img
            v-if="vfx.imageExists"
            :src="getVFXImage(vfx['name-id'])"
            alt="Image du projet"
            class="vfx-image"
            @error="handleImageError(vfx)"
          />
          <div v-else class="fallback-card">
            <h2>{{ vfx.projet }}</h2> <!-- Remplacez "title" par la propriété correcte -->
            <h4>{{ vfx.realisat }}</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- Détails du projet -->
    <VFXDetail
      v-if="Project"
      :project="Project"
      :closeVFXDetail="closeVFXDetail"
      :isYouTubeVideo="isYouTubeVideo"
      :isVimeoVideo="isVimeoVideo"
      :getYouTubeEmbedUrl="getYouTubeEmbedUrl"
      :getVimeoEmbedUrl="getVimeoEmbedUrl"
      :theme="theme"
    />

  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import VFXDetail from './VFXDetail.vue'; 
import "./VfxGrid.css";

export default {
  components: {
    VFXDetail,
  },
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  setup() {
    const vfxProjects = ref([]);
    const Project = ref(null);
    const loading = ref(true);
    const error = ref(null);

    // Fonction pour fetch les projets VFX
    const fetchVFXProjects = async () => {
      try {
        const response = await fetch("https://julian-nouveau.com/api_vfx.php");
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des projets VFX");
        }
        const data = await response.json();

        // Créer un tableau de promesses pour vérifier chaque image
        const promises = data.map(async (vfx) => {
          vfx.imageExists = await checkImageExists(vfx['name-id']);
          return vfx;
        });

        // Attendre que toutes les promesses soient résolues
        vfxProjects.value = await Promise.all(promises);

        // Trier les projets par sortOrder
        vfxProjects.value.sort((a, b) => b.sortOrder - a.sortOrder);
      } catch (err) {
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    // Vérifier si l'image existe
    const checkImageExists = (nameId) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = getVFXImage(nameId);
        img.onload = () => resolve(true); // Image chargée avec succès
        img.onerror = () => resolve(false); // Image échouée
      });
    };

    // Sélectionner un projet VFX
    const selectVFX = (vfx) => {
      Project.value = vfx;

      // Assurer que le scroll se fait seulement quand la page est totalement chargée
      window.onload = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    };

    // Fermer les détails du projet
    const closeVFXDetail = () => {
      Project.value = null;
    };

    const getVFXImage = (nameId) => {
      return `/images/affiches/${nameId}_AFFICHE.webp`;
    };

    const isYouTubeVideo = (url) => {
      return url.includes("youtube.com") || url.includes("youtu.be");
    };

    const isVimeoVideo = (url) => {
      return url.includes("vimeo.com");
    };

    const getYouTubeEmbedUrl = (url) => {
      const videoId = url.split("v=")[1]?.split("&")[0] || url.split("/").pop();
      return `https://www.youtube.com/embed/${videoId}`;
    };

    const getVimeoEmbedUrl = (url) => {
      const videoId = url.split("/").pop();
      return `https://player.vimeo.com/video/${videoId}`;
    };

    // Appeler fetchVFXProjects lorsque le composant est monté
    onMounted(fetchVFXProjects);

    return {
      vfxProjects,
      Project,
      loading,
      error,
      selectVFX,
      closeVFXDetail,
      getVFXImage,
      isYouTubeVideo,
      isVimeoVideo,
      getYouTubeEmbedUrl,
      getVimeoEmbedUrl,
    };
  },
};

</script>

<style>

</style>
