<template>
  <div class="home-page" :class="theme">
    <!-- Navigation Menu -->
    <nav class="menu">
      <button
        v-for="view in views"
        :key="view.value"
        @click="selectView(view.value)"
        :class="{ active: selectedView === view.value }"
      >
        {{ view.text }}
      </button>
    </nav>

      <div class="view-container" :key="selectedView">
        <ProjectGrid
          v-if="selectedView === 'projects'"
          :filteredProjects="filteredProjects"
          :formatOptions="formatOptions"
          @selectProject="handleSelectProject"
          :theme="theme"
        />
        <VFXGrid v-else-if="selectedView === 'vfx'" :theme="theme" />
        <ContactModule v-else-if="selectedView === 'contact'" :theme="theme" />
      </div>
  </div>
</template>

<script>
import ProjectGrid from "./ProjectGrid.vue";
import VFXGrid from "./VFXGrid.vue";
import ContactModule from "./ContactModule.vue";

export default {
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  components: {
    ProjectGrid,
    VFXGrid,
    ContactModule,
  },
  data() {
    return {
      selectedView: localStorage.getItem("selectedView") || "projects", // Default view
      projectsLoaded: false, // Flag to track project loading
      views: [
        { value: "projects", text: "Etalonnage" },
        { value: "vfx", text: "Effets Visuels" },
        { value: "contact", text: "Contact" },
      ],
      filteredProjects: [], // Initialize filteredProjects
      formatOptions: [
        { value: null, text: "Tous" },
        { value: "music", text: "Musique" },
        { value: "doc", text: "Documentaire" },
        { value: "fiction", text: "Fiction" },
        { value: "art", text: "Art" },
      ],
    };
  },
  methods: {
    selectView(view) {
      this.selectedView = view;
      localStorage.setItem("selectedView", view); // Save selected view to localStorage
      if (view === "projects") {
        this.fetchProjects(); // Fetch projects only if `projects` view is selected
      }
    },
    handleSelectProject(project) {
      console.log("Selected project:", project);
    },
    async fetchProjects() {
      // Check if projects are cached in localStorage
      const cachedProjects = localStorage.getItem("cachedProjects");
      if (cachedProjects) {
        this.filteredProjects = JSON.parse(cachedProjects);
        this.projectsLoaded = true;
        return;
      }

      // If no cache, fetch projects from API
      try {
        const response = await fetch("https://julian-nouveau.com/api.php");
        if (!response.ok) {
          throw new Error("Error fetching projects");
        }
        this.filteredProjects = await response.json();
        localStorage.setItem("cachedProjects", JSON.stringify(this.filteredProjects));
        this.projectsLoaded = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    // Preload projects on initial mount if `projects` view is selected
    if (this.selectedView === "projects") {
      this.fetchProjects();
    }
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.menu button {
  font-size: medium;
  border: none;
  border-radius: 0px;
  margin: 0 20px;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
}

.menu button:hover {
  color: rgb(55, 97, 151);
}

.menu button.active {
  text-decoration: underline;
  color: inherit;
}

.view-container {
  padding: 10px;
}



@media (max-width: 768px) {
  .menu button {
    margin: 0 10px;
  }
  .view-container {
    padding: 0;
  }
}
</style>
